import { Typography } from "@mui/material";
import React from "react";
import BookNowButton from "../components/BookNowButton";
import Footer from "../components/Footer";
import PageSection from "../components/PageSection";
import TopBar from "../components/TopBar";
import { purples } from "../theme";

const AboutPage = () => {
  return (
    <>
      <TopBar />
      <div style={{ paddingTop: "90px", backgroundColor: purples[3] }} />
      <PageSection
        id="raf"
        title="RAF LASTNAME"
        bgColor={purples[3]}
        labelColor="black"
      >
        <Typography variant="body1" gutterBottom>
          I graduated from the University of Sydney in 2012 with a Masters in
          Physiotherapy. Prior to this, I completed a Graduate Diploma in
          Exercise Prescription at ACU and a Bachelor of Exercise and Sports
          Science at Deakin University.
        </Typography>
        <Typography variant="body1" gutterBottom>
          My background as an Accredited Exercise Physiologist, coupled with my
          experience in the sub-elite setting has led to a strong interest in
          biomechanics, especially of the lower limb. I have a special interest
          in rehabilitation, specifically of the lower limb tendons, the hip and
          groin and running retraining. I place a strong emphasis on developing
          specialised strength and conditioning programs, which are sports
          specific in nature, to optimise injury rehabilitation and performance.
        </Typography>
        <Typography variant="body1" gutterBottom>
          I have a strong passion for injury prevention for all age groups with
          a special focus on the needs and treatment of adolescent athletes.
          Through my knowledge of strength training and load management I am
          able to provide programs tailored to the needs of the individual in
          facilitating improved performance and healthy outcomes.
        </Typography>
        <Typography variant="body1" gutterBottom>
          I am part of the medical and performance team with the Melbourne
          Knights Football Club. This has provided me with exposure to the elite
          athletic population which has helped in developing an expertise in the
          treatment of hip and groin pain. I have also worked with VIS level
          swimmers and pro-tennis players, where I have developed my skills in
          the treatment and management of acute and chronic shoulder injuries.
        </Typography>
        <Typography variant="body1" gutterBottom>
          To complement the above Physiotherapy roles, I also teach at
          University level.
        </Typography>
        <Typography variant="body1" gutterBottom>
          In order to continually improve my knowledge, I am currently working
          towards my APA Sports Physiotherapy title, with a view to further
          study a Masters in High Performance.
        </Typography>
        <Typography variant="body1" gutterBottom>
          As the founder of PREP Physio + Performance I am focused on providing
          a high-quality service, which involves a thorough assessment and the
          prescription of specific exercise programs, which will allow you to
          achieve peak performance.
        </Typography>
      </PageSection>
      <BookNowButton />
      <Footer />
    </>
  );
};

export default AboutPage;
